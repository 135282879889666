<template>
  <div class="container">
    <h2 style="text-align: center">"{{ this.$store.state.requests.allZayavka[0].zayavka_owner }}"</h2>
    <div class="card-body">
      <div>
        <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="allZayavka"
            single-select
            no-data-text="Malumot kiritilmagan"
            item-key="id"
            @dblclick:row="rowClick"
            loading-text="Malumot yuklanmoqda..."
            hide-default-footer
            class="row-pointer elevation-1 fixed-header"
        >
          <template v-slot:[`item.full_name`]="{ item }">
            <div class="text-black">
              {{ item.zayavka_owner }}
            </div>
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            <div class="text-black">
              {{ item.contract }}
            </div>
          </template>
          <template v-slot:[`item.deliver`]="{ item }">
            <div class="text-black" v-if="item.deliver == 'POS'">Postavshik</div>
            <div class="text-black" v-else>BTS</div>
          </template>

          <template v-slot:[`item.truck`]="{ item }">
            <div v-if="item.truck" class="table__th__truck__number__width">
              {{ item.truck.reg_number_region }} {{ item.truck.reg_number }}
            </div>
            <div
                v-else-if="item.postavshik_truck_number"
                class="table__th__truck__number__width"
            >
              {{ item.postavshik_truck_number }}
            </div>
            <div v-else class="table__th__truck__number__width">
              {{ item.postavshik_truck_info }}
            </div>
          </template>
          <template v-slot:[`item.full_name`]="{ item }" to="/">
            <div class="text-black date__width">
              {{ item.full_name | formatDate }}
            </div>
          </template>
          <template v-slot:[`item.location`]="{ item }">
            <div v-if="item.truck !== null">
              <div v-if="item.zayavka_status == 'OTW'" style="z-index: 39">
                <router-link
                    target="_blank"
                    :to="'/zayavka/map/' + item.truck_img_id"
                >
                  <div class="warning status__name click">
                    <v-icon size="18" color="white">mdi-map-marker</v-icon>
                    GPS
                  </div>
                </router-link>
              </div>
              <div v-if="item.zayavka_status == 'DEL'" style="z-index: 39">
                <div class="success status__name click">
                  <v-icon size="18" color="white">mdi-map-marker</v-icon>
                  GPS
                </div>
              </div>

              <div v-if="item.zayavka_status == 'OTB'" style="z-index: 39">
                <router-link
                    target="_blank"
                    :to="'/zayavka/map/' + item.truck_img_id"
                >
                  <div class="primary status__name click">
                    <v-icon size="18" color="white">mdi-map-marker</v-icon>
                    GPS
                  </div>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:[`item.direction`]="{ item }">
            <div class="table__th__regions__width">
            <span v-for="(city, index) in item.direction_cities" :key="city.id">
              {{ city.city_name.name }}
              <i v-if="index + 1 !== item.direction_cities.length">-</i>
            </span>
            </div>
          </template>
          <template v-slot:[`item.zayavka_status_name`]="{ item }">
            <div :class="item.status_style" class="status__name">
              {{ item.zayavka_status_name }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <ZayavkaAction :index="item" :page="page" @reload="getPostData"/>
          </template>
          <template v-slot:[`item.price_with_nds`]="{ item }">
            {{ numberWithSpaces(item.price_with_nds) || 0 }}
          </template>

          <template v-slot:[`item.postavshik_price_with_nds`]="{ item }">
            {{ numberWithSpaces(item.postavshik_price_with_nds) || 0 }}
          </template>
        </v-data-table>
      </div>

      <div class="mt-5">
        <v-pagination
            v-model="page"
            :total-visible="7"
            @input="onPageChange"
            :length="Math.ceil(getCount / 10)"
        ></v-pagination>
        <div class="row justify-content-end mt-2">
          <div class="mr-4" v-if="getCount">
            <p
                style="font-size: 15px"
                class="text-dark font-bold label label-lg label-light-success label-inline"
            >
              Soni: {{ getCount }}
            </p>
          </div>
          <div>
            <p
                style="font-size: 15px"
                class="text-dark font-bold label label-lg label-light-success label-inline"
            >
              Umumiy Summa:{{ allZayavkaTotalSumm }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZayavkaAction from './Action'
import {numberWithSpaces, takeFilledKeyValue} from '@/utils'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

export default {
  props: {
    filterProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      selected: false,
      dailyTotalSumm: 0,
      page: +this.$route.params.id || 1
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.page = +val.params.id || 1
        this.getPostData(this.page)
      }
      // immediate: true
    }
  },
  components: {
    ZayavkaAction
  },
  computed: {
    headersContract() {
      return [
        {
          text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'),
          value: 'contract_number'
        }
      ]
    },
    headers() {
      return [
        {text: this.$t('TABLE_HEADER.START_DATE'), value: 'start_date_new'},
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {text: this.$t('TABLE_HEADER.DIRECTION'), value: 'direction'},
        {text: this.$t('TABLE_HEADER.TRUCK_NUMBER'), value: 'truck'},
        // {
        //   text: this.$t('TABLE_HEADER.CLIENT'),
        //   value: 'zayavka_owner',
        //   width: '300px'
        // },
        {
          text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'),
          value: 'contract_number',
          width: '150px',
          margin:'0px'
        },

        // {
        //   text: this.$t('TABLE_HEADER.PROVIDER_CONTRACT_NUMBER'),
        //   value: 'postavshik_contract_number',
        //   width: '200px'
        // },
        {
          text: this.$t('TABLE_HEADER.ALLZAYAVKA_PRICE_WITH_NDS'),
          value: 'price_with_nds',
          width: '180px',
          margin:'0px'

        },
        {
          text: this.$t('TABLE_HEADER.ALLZAYAVKA_PRICE_WITH_NDS_POSTAVSHIK'),
          value: 'postavshik_price_with_nds',
          width: '180px',
          margin:'0px'
        },


        // { text: this.$t('TABLE_HEADER.LOCATION'), value: 'location' },

        {text: this.$t('TABLE_HEADER.STATUS'), value: 'zayavka_status_name'},
        {text: null, value: 'action', sortable: false}
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    allZayavka() {
      const userId = this.$store.state
      console.log(userId, 'props id')
      let data = this.$store.state.requests.allZayavka
      // const userId = this.$route.params.id;
      console.log(data, 'this is dataaa')
      // return data.filter(user => console.log(user.full_name));
      data = data.sort((a, b) => b.start_date_new - a.start_date_new)
      return data
    },
    allZayavkaTotalSumm() {
      return this.$store.state.requests.totalSumm
    },
    dailyZayavkaTotalSumm() {
      return this.$store.state.requests.dailyTotalSumm
    },
    getCount() {
      return this.$store.state.requests.CountZayavka
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  methods: {
    numberWithSpaces,

    onPageChange(pageNumber) {
      // this.$emit('change-page', pageNumber)
      this.getPostData(pageNumber)
      // this.$router
      //   .push({
      //     name: 'allzayavkaPAge',
      //     params: { id: pageNumber },
      //     query: {
      //       ...this.$route.query,
      //       rn: Math.random().toString().slice(2, 6)
      //     }
      //   })
      //   .catch(() => {
      //     this.getPostData(pageNumber)
      //   })
    },
    rowClick(_, row) {
      console.log(row, 'TESTTEST row')
      this.$router.push('/newzayavkadetail/' + row.item.id + '/')
      row.select(false)
    },
    getPostData(value, params) {
      let value2 = value || this.page
      let routeParams = {}

      const {start_date__year, start_date__month} = this.$route.query

      if (
          !this.filterProps.start_date_before &&
          !this.filterProps.start_date_after
      ) {
        routeParams.start_date__year = start_date__year
        routeParams.start_date__month = start_date__month
      }

      this.$store.dispatch('getAllZayavkaPageWithParams', {
        page: value2,
        params: takeFilledKeyValue({
          ...(this.filterProps || {}),
          ...routeParams,
          ...params
        })
      })
    }
  },

  mounted() {
    // let name = ''
    const {start_date__year, start_date__month} = this.$route.query
    let params = {
      start_date__year: start_date__year || currentYear,
      start_date__month: start_date__month || currentMonth,
    }

    if (this.$route.query.name) {
      params = {contract__client__full_name__icontains: this.$route.query.name}
    }

    this.$store.dispatch('getAllZayavkaPageWithParams', {
      page: 1,
      params
    })
  }
}
</script>

<style scoped>
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.text-black {
  color: #000;
}

.click {
  cursor: pointer;
}

.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}

.payment__name {
  font-weight: bold;
}

.v-application .elevation-1 {
  box-shadow: none !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}

.v-application .fixed-header {
  height: auto !important;
  overflow-y: unset !important;
}
</style>
